//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
// import { i18n } from '@/i18n';

const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
      },
      model: {},
       isPwd: true,
    };
  },

  computed: {

     // mapGetters to return loading 

   ...mapGetters({
      loading: 'auth/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    fields() {
      return fields;
    },

    // this computed  for enableRtl

    isRTL() {
      // return localStorage.getItem('language') == 'ar'
      return this.currentLanguageCode == 'ar'
    },
  },

  methods: {

     // mapAction call to  function from store { doRegisterEmailAndPassword }

    ...mapActions({
      doRegisterEmailAndPassword:
        'auth/doRegisterEmailAndPassword',
    }),


  // submitting sign up form  , first check    validation then catch error if exist , then confirm that the user type is set to admin , 
   //  finally awiat for for function
   // doRegisterEmailAndPassword  with email and password that defined already in mapActions

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

       this.model.accountType = 'admin';
       await this.doRegisterEmailAndPassword({
        email: this.model.email,
        password: this.model.password,
      });
    },

    // i18n for localization 

     i18n(key, args) {
      // return i18n(code);
      return this.$t(key, args);
    },

  },
};
